import Joi from "joi";

export const update = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required(),
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .optional()
        .allow(null, ""),
    info: Joi.string()
        .max(255)
        .trim()
        .optional()
        .allow(null, ""),
    date: Joi.date()
        .required(),
    start_hour: Joi.string()
        .regex(/^([0-9]{1,2})\:([0-9]{1,2})$/),
    end_hour: Joi.string()
        .regex(/^([0-9]{1,2})\:([0-9]{1,2})$/),
    enabled: Joi.boolean()
        .optional(),
    notes: Joi.string()
        .max(1000)
        .optional()
        .allow(null, ""),
});

export const create = Joi.object({
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .required(),
    date: Joi.date()
        .required(),
    start_hour: Joi.string()
        .regex(/^([0-9]{1,2})\:([0-9]{1,2})$/),
    end_hour: Joi.string()
        .regex(/^([0-9]{1,2})\:([0-9]{1,2})$/)
    });