<template>
    <Dialog :title="$t('call.create.title')" :open="open" @cancel="$emit('cancel')" @save="save()">
        <v-row>
            <v-col class="pb-0">    
                <v-text-field
                    :label="$t('call.create.name')"
                    v-model="form.name"
                    :error-messages="errors.name"
                    :hide-details="!errors.name"
                    @change="validate('name')"
                    outlined
                    prepend-inner-icon="mdi-video-wireless-outline"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pb-0">
                <v-menu
                    ref="menu1"
                    v-model="calendar"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateFormatted"
                            v-bind="attrs"
                            v-on="on"
                            :label="$t('call.create.date')"
                            :hide-details="!errors.date"
                            :error-messages="errors.date"
                            persistent-hint
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            outlined
                        ></v-text-field>
                    </template>

                    <v-date-picker
                            v-model="form.date"
                            @input="dateChanged"
                            :min="new Date().toISOString()"
                            no-title
                            :locale="this.$i18n.locale"
                    />
                </v-menu>
            </v-col>
        </v-row>
            <v-row>
                <v-col>
                    <v-select
                            v-model="form.start_hour"
                            :items="time"
                            :label="$t('call.create.time_start')"
                            menu-props="auto"
                            :hide-details="true"
                            @change="timeChange()"
                            prepend-inner-icon="mdi-clock"
                            outlined
                        ></v-select>
                </v-col>
                <v-col>
                    <v-select
                            v-model="form.end_hour"
                            :items="endTime"
                            item-text="text"
                            item-value="value"
                            :label="$t('call.create.time_end')"
                            menu-props="auto"
                            :hide-details="true"
                            persistent-hint
                            prepend-inner-icon="mdi-clock"
                            outlined
                        ></v-select>
                </v-col>
            </v-row>
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";
import DateInput from '../components/DateInput.vue';

import validator from "../../plugins/validator";
import * as callValidation from "../../api/call/validation";

export default {
    components: {
        Dialog,
        DateInput
    },

    async mounted() {
        this.generateStartTime();
        this.generateEndTime();
        this.form.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString();
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        entity: {
            type: String,
            default: null
        }
    },

    data: () => ({

        calendar: false,

        form: {
            name: null,
            date: null,
            start_hour: null,
            end_hour: null
        },

        errors: {
            name: null,
            date: null,
            start_hour: null,
            end_hour: null
        },

        time: [],
        endTime: [
            {
                value: null,
               
                text: null
            }
        ]
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        },

        dateFormatted: {
            get(){
                return new Date(this.form.date).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"});
            }
        },
    },

    methods: {
        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                callValidation.create,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if(!this.validate()) return
            if(this.entity) this.form.entity = this.entity;
            let res = await this.$store.dispatch("api/call/create", this.form);
             if (res.status == 200) {
                this.$router.push({ name: "call", params : { id: res.data._id } });
             }else{
             }
        },

        dateChanged(){
            this.calendar = false;
        },

        setTime(){
            this.end = this.start;
        },

        generateStartTime(){
            for(let i=0; i<24; i++) {
                for(let j=0; j<2; j++) {
                    this.time.push(i + ":" + (j===0 ? "00" : 30*j) );
                }
            }
            let d = new Date(),
                h = d.getHours(),
                m = 30 * Math.floor(d.getMinutes()/30),
                stamp = h + ":" + (m === 0 ? "00" : m);
            let pos = this.time.indexOf(stamp)
            this.form.start_hour = this.time[pos+1];
            this.form.end_hour = this.time[pos+2];
        },

        generateEndTime(){
            this.endTime = [];
            this.time.slice(this.time.indexOf(this.form.start_hour), this.time.length).map((time, i) => this.endTime[i] = {value: time});
            const index = this.endTime.length;
            this.time.slice(0, this.time.indexOf(this.form.start_hour)).map((time, i) => this.endTime[i + index] = {value: time});
            for(let i=0; i<this.endTime.length; i++){
                if(i<2){
                    this.endTime[i].text = this.endTime[i].value + " (" + i*30 + this.$t('call.min') + ")";
                }else{
                    this.endTime[i].text = this.endTime[i].value + " (" + 30*i/60 + this.$t('call.hour') + ")";
                }
            }
            this.endTime[0].disabled = true;
        },

        timeChange(){
            this.generateEndTime();
            this.form.end_hour = this.endTime[this.endTime.indexOf(this.form.start_hour) +2].value;
        },
    }
};
</script>
