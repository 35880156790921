<template>
    <Page icon="mdi-video-wireless-outline" :title="$t('routes.calls')" :progress="progress">
        <ActionBar @filter="s => load(s)" :searchProgress="searchProgress">
            <v-btn 
                @click="dialog = true"
                class="primary"
            >
            <v-icon left>mdi-plus</v-icon> {{ $t("call.create.title") }}
            </v-btn>
        </ActionBar>
        <v-tabs class="mt-3">
            <v-tab @click="tab = 0; past()">{{$t('call.tabs.actual')}}</v-tab>
            <v-tab @click="tab = 1; past()">{{$t('call.tabs.past')}}</v-tab>
        </v-tabs>
        <v-simple-table class="">
            <thead>
                <tr>
                    <th class="primary--text">
                        {{ $t("call.list.name") }}
                    </th>

                    <th class="primary--text">
                        {{ $t("call.list.type") }}
                    </th>

                    <th class="primary--text">                        
                        <SortHeader 
                            :text="$t('call.list.start')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="start"
                            type="numeric"
                        />
                    </th>

                    <th class="primary--text">
                        {{ $t("call.list.status") }}
                    </th>

                    <th class="primary--text w-action">
                        {{ $t("call.list.options") }}
                    </th>
                </tr>
            </thead>

            <tbody style="white-space: nowrap;">
                <tr v-for="(c, index) in calls" :key="index">
                    <td class="pt-3 pb-3">
                        <tr>
                            <span class="font-weight-bold">
                                {{ (c.name) ? c.name : (c.patient) ? $t("call.list.patient")+": "+c.patient.name : $t("call.no_name") }}
                            </span>
                        </tr>
                        <tr>
                            <span class="grey--text text--darken-2 d-block text-truncate" style="max-width: 400px;">
                                <v-icon size="16">mdi-clipboard-text-outline</v-icon> {{ (c.info) ? c.info : $t("call.list.no_info") }}
                            </span>
                        </tr>
                    </td>

                    <td>
                        {{$t("call.types."+c.type)}}
                    </td>

                    <td>
                        <tr>
                            <v-icon class="mr-1">mdi-calendar</v-icon>
                            {{ (new Date(c.start)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"}) }}
                            {{ (new Date(c.start)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }) }} -
                            {{ (new Date(c.end)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }) }}
                            {{ endTime(c.start, c.end) }}
                        </tr>
                    </td>

                    <td>
                         <tr>
                            <span :class="status(c.status)" v-if="c.enabled">
                                <v-icon size="16">mdi-list-status</v-icon> {{ $t("call.status." + c.status) }}
                            </span>
                            <span class="text-right red--text text--darken-2" v-else>
                                <v-icon size="16">mdi-list-status</v-icon> {{ $t("call.status.disabled") }}
                            </span>
                        </tr>
                    </td>

                    <td class="w-action">
                        <v-btn
                            isabled
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'call', params: { id: c._id, routefrom: 'calls' } }"
                        >
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination 
            class="mt-3"
            @input="load(form.filter)"
            v-if="pagination.total > 1"
            v-model="form.page"
            :length="pagination.total"
            :total-visible="7"
            />

        <CallCreate 
            v-if="dialog" 
            :entity="selectedEntity()"
            :open="dialog" 
            @cancel="dialog = false" 
            @save="load(form.filter)" 
        />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import CallCreate from "../views/dialogs/CallCreate.vue";
import SortHeader from "./components/SortHeader.vue";

export default {
    components: {
        ActionBar,
        Page,
        CallCreate,
        SortHeader
    },

    async mounted() {
        this.load();
    },

    data: () => ({
        progress: true,
        dialog: false,
        searchProgress: false,

        form: {
            sort: "start ",
            page: 1,
            limit: null,
            filter: null,
            past: false,
        },

        pagination: {
            total: 0,
            filter: null
        },

        calls: null,

        tab: 0,
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load(filter) {
            if (filter) this.form.filter = filter;
            else this.form.filter = null;

            if(this.pagination.filter != filter) this.form.page = 1;
            this.pagination.filter = filter;

            const res = await this.$store.dispatch("api/call/all", this.form);

            if (res.status == 200) {
                this.calls = res.data;
                this.pagination.total = res.pages;
            if (res.task.count == 0) {
                this.progress = false;
                if(res.status == 200) this.calls = res.data;
            }
        }
    },

    endTime(start, end){
        let time,
            diff = (new Date(end).getTime() - new Date(start).getTime())/1800000;
        if(diff<2){
            time =  " (" + diff*30 + this.$t('call.min') +")";
        }else{
            time = " (" + 30*diff/60 + this.$t('call.hour') + ")";
        }
        return time;
    },

    selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            return entity._id;
        },

    status(status){
        if (status == "idle" ) return "text-right blue-grey--text text--darken-1";
        if (status == "during" ) return "text-right light-blue--text text--darken-3";
        if (status == "end" ) return "text-right green--text text--darken-1";
        if (status == "fail" ) return "text-right red--text text--darken-2";
        if (status == "canceled" ) return "text-right red--text text--darken-2";
        return "text-right blue-grey--text text--darken-1";
    },

    past() {
        if(this.form.past){
            this.form.past = false
        }else{
            this.form.past = true;
        }
        this.load(this.form.filter);
    }

    }
};
</script>
